<template>
  <div class="flex max-w-full items-center border-b border-secondary-250 pb-2">
    <div
      v-for="(group, index) in itemsGroups"
      :key="`group-${index}`"
      class="flex gap-1 border-e last:border-0 border-secondary-300 px-1"
    >
      <TiptapMenuItem
        v-for="(item, indexP) in group.options.filter(i => !i.disabled)"
        :key="indexP"
        :item="item"
        :icon="item?.icon"
        :is-disabled="review"
        :type="item?.type || 'button'"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import TiptapMenuItem from './TiptapMenuItem.vue';
import { ref } from 'vue';
import { useEditor } from './composables/useEditor';

const { getOptionsGroups } = useEditor();

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  settings: {
    type: Object,
    default: () => ({}),
  },
  review: {
    type: Boolean,
    default: false,
  },
  disabledHighlight: {
    type: Boolean,
    default: false,
  },
});
const itemsGroups = ref(getOptionsGroups(props.id, props.disabledHighlight));
</script>

<style>
#tippy-1,
.tippy-box {
  max-width: fit-content !important;
  width: auto;
}
</style>
